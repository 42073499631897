.c-logo {
	transition: $global-transition;

	img {
		transition: $global-transition;
		max-width: 100%;
		padding: 20px 0;
		max-height: 80px;

		@include bp(medium) {
			padding: 20px 0;
			max-height: calc(80px + 40px);
		}
	}

	&--invert {
		display: none;
	}
}
