/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
	display: inline-block; /* [1] */
	vertical-align: middle; /* [2] */
	font: inherit; /* [3] */
	text-align: center; /* [4] */
	margin: 0; /* [4] */
	cursor: pointer; /* [5] */
	padding: em(12) em(24);
	transition: $global-transition;
	border-radius: $global-radius;
	border: 2px solid transparent;
	text-decoration: none; /* [4] */
}

/* Style variants
   ========================================================================== */

.c-btn--green-outline {
	background-color: transparent;
	border-color: $color-brand-1;
	color: $color-brand-1;
	border-radius: $global-radius-big;
	font-style: normal;
	font-weight: 600;
	font-size: em(16);
	line-height: 1.5;

	&:hover,
	&:active {
		color: $color-brand-1;
		background-color: rgba($color-brand-1, 0.1);
	}

	&:focus {
		@include bp-down(large) {
			color: $color-white;
			background-color: $color-brand-1;
		}
	}
}

.c-btn--gold-outline {
	background-color: transparent;
	border-color: $color-brand-2;
	color: $color-brand-2;
	border-radius: $global-radius-big;
	font-style: normal;
	font-weight: 600;
	font-size: em(16);
	line-height: 1.5;

	&:hover,
	&:active {
		color: $color-brand-2;
		background-color: rgba($color-brand-2, 0.1);
	}

	&:focus {
		@include bp-down(large) {
			color: $color-white;
			background-color: $color-brand-2;

			path {
				stroke: $color-white;
			}
		}
	}
}

.c-btn--gold-fill {
	background-color: $color-brand-2;
	border-color: $color-brand-2;
	color: $color-white;
	border-radius: $global-radius-big;
	font-style: normal;
	font-weight: 600;
	font-size: em(16);
	line-height: 1.5;

	&:hover,
	&:active {
		color: $color-white;
		background-color: $color-brand-6;
	}

	&:focus {
		@include bp-down(large) {
			color: $color-white;
			background-color: $color-brand-6;
			outline: 4px solid rgba($color-brand-2, 0.3);
		}
	}
}

.c-btn--icon {
	&-left {
		span {
			display: flex;
			margin-right: 5px;

			@include bp(large) {
				margin-right: 8px;
			}
		}
	}

	&-right {
		span {
			display: flex;
			margin-left: 5px;

			@include bp(large) {
				margin-left: 8px;
			}
		}
	}
}

/* Size variants
   ========================================================================== */

.c-btn--small {
	padding: 0.25rem 0.5rem;
}

.c-btn--large {
	padding: 0.75rem 1.5rem;
}
