.b-search {
	background-color: $color-brand-8;
	padding: 0 0 50px 0;
	margin: 75px 0 0 0;
	border-top: 2px solid $color-brand-5;

	@include bp(large) {
		margin: 115px 0 0 0;
		padding: 30px 0 90px 0;
	}

	&__heading {
		text-align: center;
		font-weight: 700;
		font-size: em(32);
		line-height: 1;
		letter-spacing: 0.01em;
		color: $color-brand-1;
		padding: 50px 0 48px 0;
		border-bottom: 2px solid $color-brand-5;

		@include bp(medium) {
			font-size: em(48);
			padding: 60px 0 48px 0;
		}
	}

	&__params {
		--size-gap: 24px;

		padding: em(50) 0 em(40) 0;
		gap: 40px var(--size-gap);

		@include bp(medium) {
			padding: em(48) 0;
		}
	}

	&__param {
		--number-columns: 1;

		@include bp(large) {
			--number-columns: 3;
		}

		@include columns;

		&--nulled {
			@include bp-down(large) {
				width: calc(100% + 2 * $wrapper-gutter);
				margin-left: (-$wrapper-gutter);
				margin-right: (-$wrapper-gutter);
			}
		}
	}

	&__button {
		width: 100%;
	}

	&__numbers {
		gap: 24px;
		padding: 18px 0 26px 0;
		max-width: 100%;
		overflow: hidden;

		@include bp-down(large) {
			padding: 20px 34px 26px 24px;
			gap: 20px;
		}
	}

	&__floor {
		border: 2px solid $color-brand-1;
		border-radius: $global-radius-big;
		padding: 10.75px 16px;
		font-weight: 500;
		font-size: em(16);
		line-height: 1.5;
		color: $color-brand-1;
		cursor: pointer;

		&--number {
			min-width: 48px;
		}

		&.active {
			color: $color-white;
			background-color: $color-brand-1;
		}

		&:not(.active):hover {
			background-color: rgba($color-brand-1, 0.1);
		}
	}

	&__name {
		font-weight: 700;
		font-size: em(20);
		line-height: 1;
		text-align: center;
		color: $color-brand-1;
		margin: 0 0 em(18) 0;

		@include bp(medium) {
			font-size: em(24);
		}
	}

	&__overflow {
		width: calc(100% - 16px - (2 * $wrapper-gutter));
		margin: 0 auto;

		@include bp(large) {
			width: calc(80% - 16px);
		}
	}

	&__name,
	&__numbers {
		@include bp(large) {
			width: 80%;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
