.b-hs {
	z-index: 2;

	h1 {
		font-weight: 700;
		font-size: em(38);
		line-height: 1;
		text-align: center;
		letter-spacing: 0.02em;
		color: $color-brand-1;

		@include bp(medium) {
			font-size: em(64);
		}
	}

	h2 {
		font-weight: 700;
		font-size: em(38);
		line-height: 1;
		text-align: center;
		letter-spacing: 0.01em;
		color: $color-brand-1;

		@include bp(medium) {
			font-size: em(48);
		}
	}

	h1,
	h2 {
		padding: 25px 0;
		margin: 0;

		@include bp(medium) {
			padding: 30px 0;
		}
	}
}
