// ==========================================================================
// #GLOBAL
// ==========================================================================
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius: 5px !default;
$global-radius-big: 10px !default;
$global-radius-bigger: 20px !default;

$global-transition: all 300ms ease-in-out !default;

/* Fonts
   ========================================================================== */
$font-name: 'Work Sans';
$font-sans-serif: $font-name, sans-serif !default;

/* Breakpoints
   ========================================================================== */

$breakpoints: (
	small: 480px,
	medium: 768px,
	large: 1024px,
	xlarge: 1280px,
	full: 1600px,
);

$wrapper-width: 1350px;
$wrapper-width-calc: 1350;
$wrapper-gutter: 15px;

/* Colors
   ========================================================================== */

// Colors shouldn't be used directly but reassigned
$color-white: #fff;
$color-black: #000;
$color-very-light-grey: #ccc;
$color-light-grey: #999;
$color-medium-grey: #666;
$color-dark-grey: #333;
$color-brand-1: #45593b;
$color-brand-2: #af8443;
$color-brand-3: #2e2e2e;
$color-brand-5: #b6b6b6;
$color-brand-6: #9d773c;
$color-brand-8: #f4f4f4;
$color-brand-9: #838383;
$color-brand-10: #ff003d;
// Text
$color-bg: $color-white;
$color-text: $color-black;

// Links
$color-link: $color-medium-grey;
$color-hover: $color-light-grey;

// Borders
$color-border: $color-very-light-grey;

// Fills
$color-fill: $color-very-light-grey;
