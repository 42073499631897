.c-hidden-phone {
	background: rgba($color-brand-2, 0.3);
	padding: 4px 12px;
	border-radius: 38px;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.43;
	display: block;
	cursor: pointer;
	color: $color-white;

	svg {
		margin-right: 6px;
	}

	&--green {
		background: rgba($color-brand-1, 0.3);
		color: $color-brand-3;

		svg {
			filter: invert(1);
		}
	}
}
