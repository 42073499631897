/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
	background: $color-brand-3;
	font-style: normal;

	&__main {
		padding: 50px 0 30px 0;
		font-style: normal;

		@include bp(medium) {
			padding: 100px 0 48px 0;
		}

		h4 {
			font-weight: 700;
			font-size: em(20);
			line-height: 1;
			color: $color-brand-2;
			margin: 0;

			@include bp(medium) {
				font-size: em(24);
				line-height: 1;
				padding: 0;
				margin: 0 0 48px 0;
			}
		}

		h5 {
			font-weight: 700;
			font-size: em(20);
			line-height: 1;
			color: $color-brand-2;
			margin: 0 0 24px 0;
		}

		p {
			font-weight: 500;
			font-size: em(16);
			line-height: 1.5;
			color: $color-white;
			margin: 0 0 48px 0;
		}

		a {
			font-weight: 500;
			font-size: em(14);
			line-height: 1.43;
			padding: 0;
			color: $color-white;
			text-decoration: none;
			display: inline-block;
			margin: 0 0 48px 0;
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin: 0 0 12px 0;

				&:last-child {
					margin: 0;
				}

				a {
					font-size: em(16);
					line-height: 1;
					padding: 14px 0;
					margin: 0;
				}
			}
		}
	}

	&__a {
		width: 100%;

		@include bp(medium) {
			width: 41.67%;
			margin-right: 8.33%;
		}
	}

	&__b,
	&__c {
		width: 100%;

		@include bp(medium) {
			width: 25%;
		}
	}

	&__a,
	&__b {
		@include bp-down(medium) {
			padding: 0 0 48px 0;
		}
	}

	&__info {
		padding: 30px 0 30px 0;
		border-top: 1px solid $color-brand-9;
		color: $color-brand-9;
		font-weight: 500;
		font-size: em(14);
		line-height: 1.43;
	}

	&__bottom {
		padding: 30px 0 50px 0;
		border-top: 1px solid $color-brand-9;

		@include bp(medium) {
			padding: 48px 0 100px 0;
		}
	}

	&__copy {
		padding: 0 30px 0 0;
		margin: 0 0 10px 0;

		@include bp(medium) {
			margin: 0;
		}
	}

	&__copy,
	&__author {
		color: $color-brand-9;
		font-weight: 500;
		font-size: em(14);
		line-height: 1.43;
		text-align: center;
		text-decoration: none;
	}

	&__icon {
		margin: 0 0 0 30px;
	}
}
