.b-faq {
	&__btn {
		width: 48px;
		height: 48px;
		border: 2px solid $color-brand-2;
		border-radius: $global-radius-big;
		margin-left: 15px;
		transition: $global-transition;

		svg {
			transition: $global-transition;
		}
	}

	&__qa {
		background-color: $color-brand-8;
		border: 2px solid $color-brand-5;
		border-radius: $global-radius-big;
		font-style: normal;
		margin-bottom: 30px;

		@include bp(medium) {
			margin-bottom: 44px;
		}
	}

	&__q {
		padding: 24px;
		font-weight: 700;
		font-size: em(24);
		line-height: 1;
		color: $color-brand-1;
		cursor: pointer;

		@include bp(medium) {
			padding: 34px 24px;
		}

		&.active {
			.b-faq__btn {
				background-color: $color-brand-2;

				svg {
					transform: rotate(180deg);
				}

				path {
					stroke: $color-white;
				}
			}
		}
	}

	&__a {
		border-top: 2px solid $color-brand-5;
		font-weight: 500;
		font-size: em(16);
		line-height: 1.5;
		color: $color-brand-3;

		&[data-accordion-content] {
			padding: 24px;

			@include bp(medium) {
				padding: 48px 24px;
			}
		}
	}

	&__list {
		margin-bottom: 160px;
	}
}
