/*
  Project: Racławicka Park
  Author: WebCrafters Studio
 */
@import '~swiper/swiper';
@import '~swiper/modules/navigation/navigation';
@import '~swiper/modules/pagination/pagination';
@import '~swiper/modules/autoplay/autoplay';
@import '~glightbox/dist/css/glightbox';
@import '~nouislider/dist/nouislider';
@import 'settings/_global.scss';
@import 'tools/_breakpoints.scss';
@import 'tools/_clearfix.scss';
@import 'tools/_columns.scss';
@import 'tools/_font.scss';
@import 'tools/_hidden.scss';
@import 'generic/_box-sizing.scss';
@import 'generic/_font-face.scss';
@import 'generic/_normalize.scss';
@import 'generic/_reset.scss';
@import 'generic/_shared.scss';
@import 'elements/_blockquote.scss';
@import 'elements/_body.scss';
@import 'elements/_headings.scss';
@import 'elements/_hr.scss';
@import 'elements/_html.scss';
@import 'elements/_images.scss';
@import 'elements/_links.scss';
@import 'elements/_lists.scss';
@import 'elements/_main.scss';
@import 'elements/_tables.scss';
@import 'vendor/_nouislider.scss';
@import 'objects/_layout.scss';
@import 'objects/_list-bare.scss';
@import 'objects/_list-inline.scss';
@import 'objects/_media.scss';
@import 'objects/_table.scss';
@import 'objects/_wrapper.scss';
@import 'components/_btn.scss';
@import 'components/_comment-form.scss';
@import 'components/_comment.scss';
@import 'components/_cookie.scss';
@import 'components/_footer.scss';
@import 'components/_form.scss';
@import 'components/_hamburger.scss';
@import 'components/_header.scss';
@import 'components/_hidden-phone.scss';
@import 'components/_logo.scss';
@import 'components/_main-nav.scss';
@import 'components/_modal.scss';
@import 'components/_post.scss';
@import 'components/_range.scss';
@import 'components/_swiper.scss';
@import 'blocks/_about-intro.scss';
@import 'blocks/_apartaments.scss';
@import 'blocks/_buttons.scss';
@import 'blocks/_faq.scss';
@import 'blocks/_form-extended.scss';
@import 'blocks/_gallery.scss';
@import 'blocks/_heading-section.scss';
@import 'blocks/_hero-icon.scss';
@import 'blocks/_hero-image.scss';
@import 'blocks/_hero.scss';
@import 'blocks/_icon-title-text.scss';
@import 'blocks/_image-heading-btn.scss';
@import 'blocks/_image-text-slider.scss';
@import 'blocks/_location.scss';
@import 'blocks/_mockup.scss';
@import 'blocks/_search.scss';
@import 'blocks/_text-background.scss';
@import 'utilities/_accordion.scss';
@import 'utilities/_align.scss';
@import 'utilities/_anchor.scss';
@import 'utilities/_clearfix.scss';
@import 'utilities/_flex.scss';
@import 'utilities/_hide.scss';
@import 'utilities/_loader.scss';
@import 'utilities/_position.scss';

.otgs-development-site-front-end {
	display: none !important;
}
