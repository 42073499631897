@mixin border($padding: 30px, $color: $color-brand-5, $position: bottom) {
	content: '';
	display: block;
	position: absolute;
	width: calc($padding * 2 + 100%);
	height: 1px;
	background-color: $color;
	left: (-$padding);
	right: 0;

	@if $position == 'top' {
		top: 0;
	} @else {
		bottom: 0;
	}
}

.b-apartaments-item {
	$padding-item: 30px;
	$image-width: 67%;
	$content-gap: 24px;

	background-color: $color-brand-8;
	border-radius: $global-radius-big;
	padding: 0 $padding-item;
	border: 2px solid $color-brand-5;
	margin: 0 0 40px 0;

	@include bp(large) {
		margin: 0 0 44px 0;
		padding: 0;
	}

	&__excerpt {
		--size-gap: 0;

		gap: var(--size-gap);
		padding: 24px 0;

		@include bp(large) {
			--size-gap: 24px;
		}
	}

	&__buttons {
		gap: 10px 24px;
		padding: 14px 0 0 0;
		position: relative;
		width: 100%;

		@include bp(large) {
			padding: 24px 0;
			border-top: 1px solid $color-brand-5;
		}

		> * {
			@include bp-down(large) {
				flex: 100%;
			}
		}
	}

	&__hide {
		position: relative;
		padding: 24px 0 0 0;
		margin: 14px 0 0 0;

		&::before {
			@include border($padding-item, $color-brand-5, top);
		}

		> * {
			@include bp-down(large) {
				flex: 100%;
			}
		}
	}

	&__basic {
		width: 100%;

		&,
		> a {
			text-align: center;
			font-weight: 500;
			font-size: em(16);
			line-height: 1.5;
			color: $color-brand-3;
		}

		> a {
			text-transform: lowercase;
			text-decoration: underline;
		}

		@include bp(large) {
			@include columns();

			--number-columns: 7;
		}

		@include bp-down(large) {
			position: relative;
			padding: 10px 0;

			&::after {
				@include border($padding-item, $color-brand-5, bottom);
			}
		}

		&--action {
			@include bp-down(large) {
				padding: 24px 0 0 0;
			}

			&::after {
				display: none;
			}

			svg {
				transition: $global-transition;
			}

			&.active {
				> * {
					color: $color-white;
					background-color: $color-brand-2;
				}

				svg {
					transform: rotate(180deg);
				}

				path {
					stroke: $color-white;
				}
			}

			&.unactive {
				@include bp-down(large) {
					display: none;
				}
			}
		}
	}

	&__basic-label {
		@include bp-down(large) {
			font-weight: 500;
			font-size: em(16);
			line-height: 1.5;
			color: $color-brand-2;
			text-transform: lowercase;
		}
	}

	&__more {
		margin: 0 auto;
		width: 100%;

		@include bp(large) {
			padding: 0;
			width: 48px;
			height: 48px;
		}

		&::before {
			@include bp-down(large) {
				content: attr(data-more);
				display: block;
				margin: 0 8px 0 0;
			}
		}

		&.active {
			svg {
				transform: rotate(180deg);
			}
		}
	}

	&__content {
		padding: 24px 0;
		gap: 10px $content-gap;
		display: none;

		@include bp(large) {
			border-top: 1px solid $color-brand-5;
			padding: 24px 0 0 0;
			gap: 24px $content-gap;
		}

		&[data-accordion-content].active {
			display: flex;
		}
	}

	&__image {
		width: 100%;
		text-align: center;

		@include bp(large) {
			width: $image-width;
		}
	}

	&__desc {
		width: 100%;

		@include bp(large) {
			padding: 0 22px;
			width: calc(100% - $image-width - $content-gap);
		}
	}

	&__text {
		@include bp-down(large) {
			font-weight: 500;
			font-size: em(16);
			line-height: 1.5;
			color: $color-brand-2;
			margin: 10px 0 12px 0;
		}
	}

	&__param {
		border-bottom: 1px solid $color-brand-5;
		padding: 10px 0;

		@include bp(large) {
			padding: 0;
			border-bottom: 2px solid $color-brand-5;
			text-transform: uppercase;
		}

		&:last-child {
			border-bottom: 0;
		}

		&--calc {
			@include bp-down(large) {
				border-bottom: 0;
			}
		}
	}

	&__label {
		line-height: 1.43;
		font-size: em(14);
		font-weight: 500;
		color: $color-brand-2;
		padding: 0 20px 0 0;

		@include bp(large) {
			padding: 0 10px 0 22px;
			color: $color-brand-3;
		}
	}

	&__value {
		line-height: 1.43;
		font-size: em(14);
		font-weight: 500;
		color: $color-brand-3;
	}

	&__empty {
		color: $color-brand-3;
		font-size: em(18);
		text-align: center;
		padding: 30px 15px;
	}
}
