.b-apartaments-sort {
	--size-gap: 24px;
	$self: &;

	gap: var(--size-gap);
	padding: 0 $wrapper-gutter;
	background-color: $color-white;

	@include bp(large) {
		padding: 44px 0;
	}

	&__header {
		font-weight: 700;
		font-size: em(24);
		line-height: 1;
		letter-spacing: 0.01em;
		color: $color-brand-1;
		border-bottom: 1px solid $color-brand-8;
		width: calc(100% + 2 * $wrapper-gutter);
		padding: 20px $wrapper-gutter;
		margin: 0 (-$wrapper-gutter);
	}

	&__btn {
		@include bp-down(large) {
			--number-columns: 2;

			border-radius: $global-radius-big;
			border: 2px solid $color-brand-2;
			padding: 12px 14.5px;

			@include columns();
		}

		svg {
			margin: 0 0 0 5px;

			@include bp-down(large) {
				margin: 0 8px 0 0;
			}
		}

		path {
			stroke: $color-brand-5;
		}

		span {
			color: $color-brand-2;
		}

		&.active {
			path {
				stroke: $color-brand-2;
			}
		}
	}

	&__value {
		--number-columns: 1;

		@include columns();

		font-weight: 700;
		font-size: em(20);
		line-height: 1;
		text-align: center;
		color: $color-brand-1;
		cursor: pointer;

		@include bp(large) {
			--number-columns: 7;

			color: $color-brand-2;
			text-transform: lowercase;
		}

		&.up,
		&.down {
			path {
				@include bp(large) {
					stroke: $color-brand-2;
				}
			}
		}

		&.up {
			svg {
				@include bp(large) {
					transform: rotate(180deg);
				}
			}

			#{$self}__btn--up {
				path {
					stroke: $color-brand-2;
				}
			}
		}

		&.down {
			#{$self}__btn--down {
				path {
					stroke: $color-brand-2;
				}
			}
		}
	}

	&__actions {
		@include bp-down(large) {
			--size-gap: 10px;
			$size-gap-row: 20px;

			gap: $size-gap-row var(--size-gap);
			margin: $size-gap-row 0 0 0;
			width: 100%;
		}
	}

	&__set {
		background-color: rgba($color-brand-2, 0.1);
		width: calc(100% + 2 * $wrapper-gutter);
		padding: 20px $wrapper-gutter;
		margin: 0 (-$wrapper-gutter);

		> * {
			flex: 1;
		}
	}

	&__show {
		margin: 40px 0;
		width: 100%;
	}
}
