$padding-top: 24px;

[data-accordion-content] {
	display: none;
	padding-top: $padding-top;

	&.active {
		display: block;
	}
}

[data-accordion-content-m] {
	@include bp-down(medium) {
		display: none;
		padding-top: $padding-top;

		&.active {
			display: block;
		}
	}
}

[data-accordion-btn] {
	div {
		transition: $global-transition;
	}

	&,
	button {
		cursor: pointer;
	}

	&.active {
		div {
			transform: rotate(180deg);
		}
	}
}
