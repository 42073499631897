/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
	color: $color-link;
	text-decoration: underline;
	transition: $global-transition;

	&:hover {
		color: $color-hover;
	}
}
