.b-about-intro {
	$content-width: 560;

	--padding-space: 109px;
	--padding-wrapper: 30px;

	padding: var(--padding-space) 0 0 0;
	overflow-y: visible;

	@include bp(medium) {
		--padding-space: 240px;
		--padding-wrapper: 80px;
	}

	&__wrapper {
		padding-bottom: var(--padding-wrapper);

		@include bp(medium) {
			padding-bottom: var(--padding-wrapper);
		}

		&::before {
			background-color: $color-brand-2;
			height: 5px;
			width: 33.33%;
			z-index: 2;
		}

		&::after {
			background-color: $color-brand-5;
			height: 5px;
			width: calc(100% - 30px);
			z-index: 1;
		}

		&::after,
		&::before {
			content: '';
			display: block;
			position: absolute;
			border-radius: 5px;
			left: auto;
			right: auto;
			bottom: 0;
		}
	}

	&__content {
		font-style: normal;
		max-width: 100%;
		z-index: 2;

		@include bp(medium) {
			width: calc(($content-width * 100%) / $wrapper-width-calc);
			padding: 0 60px;
		}

		h2 {
			font-weight: 700;
			font-size: em(32);
			line-height: 1;
			letter-spacing: 0.01em;
			color: $color-brand-2;
			margin: 0 0 30px 0;

			@include bp(medium) {
				font-size: em(48);
				margin: 0 0 40px 0;
			}
		}

		p {
			font-weight: 500;
			font-size: em(14);
			line-height: 1.43;
			color: $color-brand-3;
			margin: 0 0 30px 0;

			@include bp(medium) {
				margin: 0 0 40px 0;
				font-size: em(18);
				line-height: 1.44;
			}
		}

		> a {
			width: 100%;
		}
	}

	&__gallery {
		z-index: 2;
		margin: 50px -15px 0 0;

		@include bp(medium) {
			margin: 0;
			width: calc((($wrapper-width-calc - $content-width) * 100%) / $wrapper-width-calc);
		}

		img {
			border-radius: $global-radius-bigger;
			max-width: 100%;
			height: auto;
			object-fit: cover;
		}
	}

	&__swiper {
		@include bp(medium) {
			// width: calc(100% + 16.6vw);
		}
	}

	&__graphic {
		position: absolute;
		max-width: calc(100% - ($wrapper-gutter * 4));
		margin: 0 auto;
		bottom: var(--padding-space);
		right: 0;
		left: 0;
		z-index: 1;

		@include bp(medium) {
			top: calc((var(--padding-space) + 18px) * -1);
			bottom: auto;
			left: auto;
			right: $wrapper-gutter;
			max-width: 100%;
		}
	}
}
