/* stylelint-disable no-descending-specificity */
.c-form {
	display: flex;
	flex-wrap: wrap;
	font-family: $font-sans-serif;

	--background-input: #{$color-brand-3};
	--border-input: #{$color-brand-5};
	--text-input: #{$color-white};
	--text-placeholder: #{$color-brand-5};
	--border-checkbox: #{$color-white};
	--border-checkbox-active: #{$color-white};

	&--light {
		--background-input: #{$color-white};
		--border-input: #{$color-brand-5};
		--text-input: #{$color-brand-3};
		--text-placeholder: #{$color-brand-9};
		--border-checkbox: #{$color-brand-3};
		--border-checkbox-active: #{$color-brand-3};
		--label-section: #{$color-brand-1};
	}

	label {
		position: relative;
	}

	textarea {
		height: 96px;

		+ .wpcf7-not-valid-tip {
			bottom: -15px;
		}
	}

	input,
	textarea,
	&__selected {
		&:not([type='submit']) {
			font-family: inherit;
			width: 100%;
			background-color: var(--background-input);
			border: 2px solid var(--border-input);
			border-radius: $global-radius-big;
			font-style: normal;
			font-weight: 500;
			font-size: em(16);
			line-height: 1.5;
			padding: 10px;
			color: var(--text-input);
			transition: $global-transition;

			&::placeholder {
				color: var(--text-placeholder);
			}

			&:focus {
				border-color: $color-brand-2;
				background: rgba($color-brand-2, 0.1);
				outline: 4px solid rgba($color-brand-2, 0.3);
			}
		}

		&.wpcf7-not-valid {
			border-color: $color-brand-10;
		}
	}

	&__row {
		--column--gap: 12px;
		--row--gap: 18px;

		display: flex;
		gap: var(--row--gap) var(--column--gap);
		justify-content: space-between;
		align-items: flex-start;
		margin: 0 0 calc((var(--row--gap) * 2) + var(--column--gap)) 0;
		flex-wrap: wrap;
		width: 100%;

		label {
			width: 100%;

			@include bp(medium) {
				flex: 1;
			}
		}
		// &--half {
		// }
		&--modal {
			--row--gap: 4px;

			@include bp(large) {
				--row--gap: 9px;
			}
		}
	}

	&__label-value {
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 1.67;
		margin: 0 0 5px 0;
		color: var(--text-input);
		display: block;
	}

	&__label-section {
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 1;
		padding: em(20) 0;
		color: var(--label-section);
		display: block;
		width: 100%;

		&--p-null {
			padding: 0;
		}
	}

	&__submit {
		width: 100%;

		input {
			width: 100%;
		}
	}

	&__checkbox {
		a {
			text-decoration: underline;
			margin: 0;
			display: inline-flex;
		}

		.wpcf7-list-item,
		.list-item {
			margin: 0;
			padding: 0;
			transition: $global-transition;

			> label {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				cursor: pointer;
			}
		}

		.wpcf7-list-item-label,
		.list-item-label {
			font-weight: 400;
			font-size: em(12);
			line-height: 1.33;
			color: var(--text-input);
			margin: 0;
			padding: 3px 0 0 48px;
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			flex-wrap: wrap;
			position: relative;
			min-height: 24px;
			font-style: italic;

			> span {
				font-style: normal;
				font-weight: 500;
			}

			a {
				color: var(--text-input);
			}

			&::before {
				content: '';
				width: 24px;
				height: 24px;
				background-color: var(--background-input);
				border: 2px solid var(--border-checkbox);
				border-radius: $global-radius;
				display: flex;
				position: absolute;
				left: 0;
				top: 0;
			}

			&::after {
				content: '';
				width: 12px;
				height: 12px;
				background-color: var(--border-checkbox-active);
				border-radius: 2px;
				position: absolute;
				left: 6px;
				top: 6px;
				opacity: 0;
				transition: $global-transition;
			}
		}

		input[type='checkbox'] {
			display: none;

			&:checked + .wpcf7-list-item-label,
			&:checked + .list-item-label {
				&::before {
					border-color: var(--border-checkbox-active);
				}

				&::after {
					opacity: 1;
				}
			}
		}
	}

	.wpcf7-acceptance {
		display: block;

		&.wpcf7-not-valid {
			margin-bottom: 5px;
		}

		+ .wpcf7-not-valid-tip {
			position: relative;
			bottom: auto;
			padding-right: 40px;
			margin-bottom: 5px;

			&::after {
				top: -4px;
			}
		}
	}

	.wpcf7-not-valid-tip {
		color: $color-brand-10;
		font-weight: 500;
		font-size: em(12);
		line-height: 1.5;
		margin: 1px 0 0 0;
		position: absolute;
		bottom: -35px;
		width: 100%;

		&::after {
			content: '';
			background-image: url('~assets/images/icon-error.svg');
			display: block;
			width: 24px;
			height: 24px;
			position: absolute;
			top: -38px;
			right: 12px;
		}
	}

	.wpcf7-response-output {
		color: var(--text-input);
		padding: 12px !important;
		width: 100%;
		font-size: em(12);
		border-radius: $global-radius;
		text-align: center;
		margin: 12px 0 !important;
	}

	&.invalid {
		.wpcf7-response-output {
			border: 2px solid $color-brand-10 !important;
		}
	}

	.wpcf7-select {
		display: none;
	}

	&__select {
		position: relative;
	}

	&__selected {
		cursor: pointer;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&::after {
			transition: $global-transition;
			content: url('~assets/images/icon-arrow-down.svg');
			height: 24px;
			width: 24px;
		}

		&--arrow-active {
			border-color: $color-brand-2 !important;
			background: rgba($color-brand-2, 0.1);
			outline: 4px solid rgba($color-brand-2, 0.3);
			background-color: rgba($color-brand-2, 0.1) !important;

			&::after {
				transform: rotate(180deg);
			}
		}
	}

	&__select-items {
		position: absolute;
		background-color: $color-white;
		margin-top: 10px;
		top: 100%;
		left: 0;
		right: 0;
		z-index: 99;
		border: 2px solid $color-brand-5;
		border-radius: $global-radius-big;

		div {
			color: $color-brand-3;
			padding: 12px 10px;
			font-weight: 500;
			font-size: em(16);
			cursor: pointer;

			&:hover {
				background-color: rgba($color-brand-2, 0.1);
			}
		}

		.same-as-selected {
			background-color: rgba($color-brand-2, 0.1);
			display: flex;
			align-items: center;
			justify-content: space-between;

			&::after {
				transition: $global-transition;
				content: url('~assets/images/icon-check.svg');
				height: 24px;
				width: 24px;
			}
		}
	}

	&__cat {
		color: var(--text-input);
		font-weight: 600;
		font-size: em(16);
		line-height: 1.33;
	}

	.hidden {
		display: none;
	}
}
/* stylelint-enable no-descending-specificity */
