.b-hi {
	&::before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 20.31%, rgba(0, 0, 0, 0) 100%);
		z-index: 1;
	}

	img {
		object-fit: cover;
		object-position: center;
		width: 100%;
		height: 27vh;
		z-index: 2;

		@include bp(medium) {
			height: 53.2vh;
		}
	}
}
