.b-hero-icon {
	font-style: normal;
	text-align: center;
	padding: 120px 0 40px 0;

	&__wrapper {
		@include bp(medium) {
			padding: 185px 0 90px 0;
		}
	}

	&__icon {
		margin: 0 0 12px 0;
	}

	&__heading {
		font-size: em(32);
		line-height: 1;
		text-align: center;
		letter-spacing: 0.01em;
		color: $color-brand-1;

		@include bp(medium) {
			letter-spacing: 0.02em;
			font-size: em(64);
		}
	}

	&__bg {
		position: absolute;
		right: 0;
		bottom: 0;
		transform: translate(20%, 70%);
		z-index: -1;
		max-width: 194px;

		@include bp(medium) {
			transform: translate(45%, 60%);
			max-width: 100%;
		}
	}
}
