.b-buttons {
	padding: 33px 0 0;

	@include bp(medium) {
		padding: 33px 0 75px;
	}

	&__wrapper {
		gap: 24px;

		@include bp(medium) {
			padding: 0 calc(8.33% - 30px);
		}

		a {
			flex: 1;
		}
	}

	&__link {
		&--single {
			max-width: 424px;
			margin: 0 auto;
		}
	}
}
