.b-ihb {
	padding: 80px 0;
	margin: 75px 0;
	$content-width: 873;

	&::before {
		content: '';
		display: block;
		background-image: var(--background);
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		width: 50%;
		height: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: 1;
		border-radius: 0 $global-radius-big $global-radius-big 0;

		@include bp(medium) {
			display: none;
		}
	}

	@include bp(medium) {
		padding: 190px 0;
		margin: 0;
	}

	&--empty {
		padding: 80px 0 0 0;
		margin: 0;

		@include bp(medium) {
			padding: 190px 0 0 0;
			margin: 0;
		}
	}

	&__btn {
		width: 100%;
	}

	&__heading {
		font-style: normal;
		font-weight: 700;
		line-height: 1;
		text-align: center;
		letter-spacing: 0.01em;
		color: $color-brand-1;
		margin: 0 0 25px 0;

		@include bp(medium) {
			font-size: em(48);
			margin: 0 0 50px 0;
		}
	}

	&__content {
		width: 100%;
		z-index: 2;

		@include bp(medium) {
			padding: 0 0 0 45px;
			width: calc((($wrapper-width-calc - $content-width) * 100%) / $wrapper-width-calc);
		}
	}

	&__image {
		width: 100%;
		display: none;

		@include bp(medium) {
			display: block;
			width: calc(($content-width * 100%) / $wrapper-width-calc);
		}
	}

	&__icon {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin-left: auto;
		margin-right: auto;
		margin-top: auto;
		margin-bottom: auto;
	}
}
