.b-apartaments-floors {
	background: $color-white;
	margin: 0 (-$wrapper-gutter);
	padding: 30px 0 0 0;
	position: relative;
	z-index: 2;
	box-shadow: 0 0 10px rgba($color-black, 0.1);

	@include bp(large) {
		border-radius: $global-radius-big;
		margin: 0;
		padding: 0;
	}

	strong {
		font-weight: 500;
		font-size: em(16);
		line-height: 1.5;
		color: $color-black;
		margin-right: 20px;
	}

	&__numbers {
		gap: 24px;
		padding: 36px 0;
		background: $color-white;
		max-width: 100%;

		@include bp-down(large) {
			overflow: hidden;
			// overflow-x: scroll;
			padding: 20px 34px 36px 24px;
			gap: 20px;
		}
	}

	&__overflow {
		max-width: calc(100% - 48px);
		margin: 0 auto 40px auto;
	}

	&__floor {
		border: 2px solid $color-brand-1;
		border-radius: $global-radius-big;
		padding: 10.75px 16px;
		font-weight: 500;
		font-size: em(16);
		line-height: 1.5;
		color: $color-brand-1;
		cursor: pointer;

		&--number {
			min-width: 48px;
		}

		&.active {
			color: $color-white;
			background-color: $color-brand-1;
		}

		&:not(.active):hover {
			background-color: rgba($color-brand-1, 0.1);
		}
	}

	&__projects {
		img {
			@include bp(large) {
				border-radius: 0 0 $global-radius-big $global-radius-big;
			}
		}

		.swiper-slide {
			@include bp-down(large) {
				margin-bottom: 40px;
			}
		}
	}

	&__all {
		display: none;

		@include bp-down(large) {
			min-height: 294px;
			object-fit: cover;
		}

		&.active {
			display: block;
		}
	}

	&__nav {
		&.active {
			display: flex;
			border-top: 2px solid$color-brand-8;
			padding: 36px 0 26px 0;
		}
	}

	&__index {
		font-weight: 700;
		font-size: em(20);
		line-height: 1;
		text-align: center;
		color: $color-brand-2;
		padding: 0 36px;

		&::after {
			content: attr(data-floor-index);
			display: block;
			margin-left: 5px;
		}
	}
}
