.u-p {
	&-relative {
		position: relative;
	}

	&-md {
		&-relative {
			@include bp(medium) {
				position: relative;
			}
		}
	}
}
