/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
	margin-right: auto;
	margin-left: auto;
	max-width: $wrapper-width;
	padding-left: $wrapper-gutter;
	padding-right: $wrapper-gutter;
}
