.noUi-target {
	background-color: $color-brand-5;
	box-shadow: none;
	border-radius: $global-radius;
	border: none;
	height: 4px;
}

.noUi-connect {
	background-color: $color-brand-1;
}

.noUi-horizontal {
	.noUi-handle {
		height: 16px;
		width: 16px;
		background: $color-brand-1;
		box-shadow: none;
		border-radius: $global-radius-bigger;
		border-color: $color-brand-1;
		right: -8px;

		&::before,
		&::after {
			display: none;
		}
	}

	.noUi-marker-horizontal {
		&.noUi-marker-normal {
			display: none;
		}

		&.noUi-marker {
			margin-left: -5px;
		}

		&.noUi-marker-large {
			height: 8px;
			width: 4px;
			top: -6px;
			border-radius: $global-radius-big;
			background: $color-brand-1;
		}
	}

	.noUi-value-horizontal {
		&.noUi-value {
			display: none;
		}
	}
}
