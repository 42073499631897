.b-fe {
	$b-fm-gap: 24px;

	background: transparent;
	background: linear-gradient(3600deg, rgba(244, 244, 244, 1) 0%, rgba(244, 244, 244, 1) 63%, transparent 63%);

	&__heading {
		font-style: normal;
		font-weight: 700;
		font-size: em(24);
		line-height: 1;
		letter-spacing: 0.01em;
		color: $color-brand-1;
		margin: 0 0 30px 0;

		@include bp(medium) {
			font-size: em(32);
			margin: 0 0 48px 0;
		}
	}

	&__form {
		padding: 30px 24px;
		background-color: $color-white;
		box-shadow: 0 0 10px rgba($color-black, 0.1);
		// margin-bottom: 50px;
		margin: 0 -15px 50px -15px;

		@include bp(medium) {
			border-radius: $global-radius-big;
			padding: 72px 109px;
			margin-bottom: 72px;
		}
	}

	&__addresses {
		padding: 48px 24px 40px 24px;
		border-top: 2px solid $color-brand-5;
		gap: $b-fm-gap;

		@include bp(medium) {
			padding: 48px 0 80px 0;
		}
	}

	&__address {
		--b-itt-columns: 1;

		width: calc((100% / var(--b-itt-columns)) - $b-fm-gap + ($b-fm-gap / var(--b-itt-columns)));

		@include bp(small) {
			--b-itt-columns: 2;
		}

		@include bp(medium) {
			--b-itt-columns: 4;
		}
	}

	&__data {
		margin-left: 12px;
	}

	&__title {
		font-style: normal;
		font-weight: 700;
		font-size: em(20);
		line-height: 1;
		color: $color-brand-1;
		margin: 0 0 12px 0;
		display: block;
		text-decoration: none;
	}

	a {
		text-decoration: inherit;

		&:hover {
			color: $color-brand-1;
		}
	}
}
