.b-its {
	&__heading {
		font-weight: 700;
		font-size: em(32);
		line-height: 1;
		text-align: left;
		letter-spacing: 0.01em;
		color: $color-brand-1;
		margin: 0 0 30px 0;
		width: 100%;

		@include bp(medium) {
			font-size: em(48);
			width: 50%;
			margin: 0;
		}
	}

	&__slider {
		margin: -90px (-$wrapper-gutter) -30px (-$wrapper-gutter);
		padding: 0 $wrapper-gutter 30px $wrapper-gutter;

		@include bp(medium) {
			padding: 0 0 30px 0;
			margin: -40px 0 -30px 0;
		}
	}

	&__slide {
		filter: drop-shadow(0 0 10px rgba($color-black, 0.1));
		border-radius: $global-radius-big;
		background: $color-white;
		height: auto;

		img {
			border-radius: 10px 10px 0 0;
			width: 100%;
			min-height: 220px;
			object-fit: cover;
		}
	}

	&__top {
		border-top: 2px solid $color-brand-5;
		background-color: $color-brand-8;
		padding: 60px 0 120px 0;

		@include bp(medium) {
			padding: 112px 0;
		}
	}

	&__navigation {
		gap: 24px;
		width: 100%;

		@include bp(medium) {
			width: 50%;
		}
	}

	hr {
		display: block;
		width: 100%;
		border: 1px solid $color-brand-5;
		background: none;
		margin: 0;
		padding: 0;
	}

	&__prev {
		margin-right: 24px;
	}

	&__prev,
	&__next {
		margin-top: 0;
		position: static;
	}

	&__text {
		font-weight: 500;
		font-size: 20px;
		line-height: 1.5;
		color: $color-brand-3;
		padding: 24px;
		border-radius: 0 0 10px 10px;
		margin: 0;
	}
}
