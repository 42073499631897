.swiper {
	--swiper-navigation-size: 48px;
	--swiper-navigation-color: #{$color-brand-2};

	.swiper-button-prev--padding,
	.swiper-button-next--padding {
		background-color: $color-white;
		margin: 0;
		top: 0;
		height: 100%;
		width: var(--swiper-navigation-size);

		&::after {
			display: none;
		}

		&.swiper-button-prev {
			left: 0;
		}

		&.swiper-button-next {
			right: 0;
		}
	}

	&-prev,
	&-next {
		width: var(--swiper-navigation-size);
		height: var(--swiper-navigation-size);
		background-color: rgba($color-brand-2, 0.3);
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: $global-radius-big;
		transition: $global-transition;
		padding: 10px;
		cursor: pointer;

		&::after {
			display: none;
		}

		&:hover {
			background-color: rgba($color-brand-2, 0.4);
		}

		&.swiper-button-lock {
			display: none;
		}

		&.swiper-button-disabled {
			opacity: 0.35;
			cursor: not-allowed;
		}
	}
}
