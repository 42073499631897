.b-apartaments-meeting {
	padding: 0 $wrapper-gutter;
	background-color: $color-white;

	&__header {
		font-weight: 700;
		font-size: em(24);
		line-height: 1;
		letter-spacing: 0.01em;
		color: $color-brand-1;
		border-bottom: 1px solid $color-brand-8;
		width: calc(100% + 2 * $wrapper-gutter);
		padding: 30px 20px;
		margin: 0 (-$wrapper-gutter);

		@include bp(large) {
			border-bottom: 0;
			font-size: em(32);
			padding: 30px 36px;
		}
	}

	&__submit {
		background: rgba($color-brand-2, 0.1);
		padding: 20px;
		margin: 0 (-$wrapper-gutter);
		width: calc(100% + $wrapper-gutter * 2);

		@include bp(large) {
			padding: 30px 36px;
		}

		button {
			width: 100%;

			@include bp(large) {
				margin: 0 auto;
				width: auto;
			}
		}

		.wpcf7-spinner {
			display: none;
		}
	}
}
