.b-apartaments-form {
	padding: 265px 0 0 0;
	margin: -265px 0 0 0;
	background-color: $color-brand-8;

	&__heading {
		text-align: center;
		font-weight: 700;
		font-size: em(32);
		line-height: 1;
		letter-spacing: 0.01em;
		color: $color-brand-1;
		padding: 60px 0 48px 0;
		border-bottom: 2px solid $color-brand-5;

		@include bp(medium) {
			font-size: em(48);
		}
	}

	&__params {
		--size-gap: 24px;

		padding: em(50) 0 em(40) 0;
		gap: 40px var(--size-gap);

		@include bp(medium) {
			padding: em(48) 0 em(90) 0;
		}
	}

	&__param {
		--number-columns: 1;

		@include bp(large) {
			--number-columns: 3;
		}

		@include columns;
	}

	&__button {
		width: 100%;

		@include bp(large) {
			width: 80%;
		}
	}
}
