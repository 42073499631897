.b-itt {
	$b-itt-gap: 24px;
	$b-itt-border: 2px;

	z-index: 1;

	&--slide {
		padding: 5px 0 50px 0;

		@include bp(medium) {
			padding: 70px 0 160px 0;
		}
	}

	@include bp(medium) {
		padding: 30px 0 160px 0;
	}

	&__wrapper {
		gap: $b-itt-gap;
	}

	&__swiper {
		@include bp(large) {
			padding: 0 72px;
		}
	}

	&__box {
		--b-itt-columns: 1;

		font-style: normal;
		text-align: center;
		padding: 40px 20px;
		background-color: $color-white;
		border-radius: $global-radius-bigger * 2;
		border: solid $b-itt-border transparent;
		box-sizing: border-box;
		width: 100%;
		width: calc((100% / var(--b-itt-columns)) - $b-itt-gap + ($b-itt-gap / var(--b-itt-columns)));

		@include bp(medium) {
			--b-itt-columns: 2;
		}

		@include bp(large) {
			--b-itt-columns: 4;
		}

		&--featured {
			position: relative;
			background-clip: padding-box;
			transform: initial !important;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: -1;
				border-radius: inherit;
				margin: -$b-itt-border;
				background: linear-gradient(50deg, $color-brand-2 20%, transparent 75%, $color-white 100%);
			}
		}

		&--slide {
			padding: 0 45px;
		}
	}

	&__swiper-next {
		right: 0;
	}

	&__swiper-prev {
		left: 0;
	}

	&__icon {
		margin: 0 auto 20px auto;
		max-width: 130px;
	}

	&__title {
		font-style: normal;
		font-weight: 700;
		font-size: em(20);
		line-height: 1;
		text-align: center;
		color: $color-brand-2;
		margin: 0 0 20px 0;
	}

	&__text {
		font-weight: 500;
		font-size: em(16);
		line-height: 1.5;
		color: $color-brand-3;
	}
}
