/* ==========================================================================
   #FONT-FACE
   ========================================================================== */

/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
@font-face {
	font-family: $font-name;
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url('~assets/fonts/WorkSans-Light.ttf') format('truetype');
}

@font-face {
	font-family: $font-name;
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('~assets/fonts/WorkSans-Regular.ttf') format('truetype');
}

@font-face {
	font-family: $font-name;
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url('~assets/fonts/WorkSans-Medium.ttf') format('truetype');
}

@font-face {
	font-family: $font-name;
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url('~assets/fonts/WorkSans-SemiBold.ttf') format('truetype');
}

@font-face {
	font-family: $font-name;
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('~assets/fonts/WorkSans-Bold.ttf') format('truetype');
}

@font-face {
	font-family: $font-name;
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url('~assets/fonts/WorkSans-ExtraBold.ttf') format('truetype');
}

@font-face {
	font-family: $font-name;
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url('~assets/fonts/WorkSans-Black.ttf') format('truetype');
}
