.b-location {
	min-height: calc(796px + 96px);
	margin-bottom: 75px;

	&__content {
		@include bp(large) {
			max-width: calc(100% / 12 * 7);
			margin-left: auto;
			margin-bottom: 195px;
			position: relative;
		}
	}

	&__form {
		background: transparent;
		z-index: 2;
	}

	&__text,
	&__form {
		padding: 50px 24px;
		margin: 0 -15px;

		@include bp(large) {
			margin: 0 0 0 auto;
			padding: 66px 66px 66px 194px;
		}

		@include bp(full) {
			padding: 66px 112px 66px 224px;
		}
	}

	&__text {
		background: $color-brand-1;
		z-index: 2;

		@include bp(large) {
			border-radius: $global-radius-big;
		}
	}

	&__heading {
		font-weight: 700;
		font-size: em(24);
		line-height: 1;
		letter-spacing: 0.01em;
		color: $color-white;
		margin: 0 0 30px 0;

		@include bp(large) {
			font-size: em(32);
			margin: 0 0 44px 0;
		}
	}

	&__desc {
		font-weight: 500;
		font-size: em(14);
		line-height: 1.43;
		color: $color-white;
		margin: 0;

		@include bp(medium) {
			font-size: em(18);
			line-height: 1.44;
		}
	}

	&__img {
		position: absolute;
		top: calc(27% + 24px);
		right: -70px;
		z-index: 1;
		max-height: 154px;

		@include bp(large) {
			top: calc(33%);
			right: -166px;
			transform: none;
		}
	}

	&__form-title {
		font-weight: 700;
		font-size: em(20);
		line-height: 1;
		color: $color-brand-2;
		margin: 0 0 44px 0;
		width: 100%;

		@include bp(medium) {
			font-size: em(24);
		}
	}

	&__form-terms {
		gap: 24px;
	}

	&__form-term {
		--b-location-columns: 1;
		--b-location-gap: 24px;
		--background-input: $color-white;
		--border-checkbox: #{$color-brand-5};
		--text-input: #{$color-brand-9};
		--border-checkbox-active: #{$color-brand-2};

		cursor: pointer;
		width: calc(
			(100% / var(--b-location-columns)) - var(--b-location-gap) +
				(var(--b-location-gap) / var(--b-location-columns))
		);
		font-weight: 500;
		font-size: 16px;
		line-height: 1.5;

		.list-item-label {
			align-items: center;
		}

		input[type='checkbox'] {
			&:checked + .list-item-label {
				color: $color-brand-2;
			}
		}

		@include bp(medium) {
			--b-location-columns: 2;
		}
	}

	&__map {
		height: 300px;
		margin: 0 -15px;
		order: 3;
		z-index: 3;
		position: relative;

		@include bp(large) {
			position: absolute;
			top: 94px;
			left: calc(-50vw + 77px);
			width: calc(50vw);
			height: 796px;
			margin: 0;
		}

		@include bp(full) {
			left: calc(-50vw + 88px);
		}

		> div {
			@include bp(large) {
				border-radius: 0 $global-radius-big $global-radius-big 0;
			}
		}
	}
}
