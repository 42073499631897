/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	transition: $global-transition;

	@include bp-down(medium) {
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 20.31%, rgba(0, 0, 0, 0) 100%);
	}

	&.scrolled {
		background: $color-brand-1;

		.c-logo img {
			max-height: 70px;

			@include bp(medium) {
				max-height: 80px;
			}
		}

		.c-main-nav__dropdown {
			@include bp(large) {
				background: $color-brand-1;
			}
		}
	}

	&--colored {
		background: $color-white;
		box-shadow: 0 2px 4px rgba($color-black, 0.05);

		&.scrolled {
			.c-logo {
				display: none;

				&--invert {
					display: block;
				}
			}

			.c-main-nav--colored {
				@include bp(large) {
					--main-color-text: #{$color-white};
				}
			}

			.c-hamburger__open {
				path {
					stroke: $color-white;
				}
			}
		}
	}
}
