.u-loader-section {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba($color-brand-3, 0.5);
	width: 100%;
	height: 100%;
	z-index: 50;
	display: none;

	&.active {
		display: flex !important;
	}
}

.u-loader {
	display: inline-block;
	position: relative;
	width: 70px;
	height: 17px;
	margin: 0 auto;
}

.u-loader div {
	display: inline-block;
	position: absolute;
	left: 8px;
	width: 5px;
	background: $color-white;
	border-radius: $global-radius;
	animation: facebook 1.2s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
}

.u-loader div:nth-child(1) {
	left: 8px;
	animation-delay: -0.24s;
}

.u-loader div:nth-child(2) {
	left: 32px;
	animation-delay: -0.12s;
}

.u-loader div:nth-child(3) {
	left: 56px;
	animation-delay: 0;
}

@keyframes facebook {
	0% {
		top: 0;
		height: 30px;
	}

	50%,
	100% {
		top: 10px;
		height: 5px;
	}
}
