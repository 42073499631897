@use 'sass:math';
$hero-bottom-offset: 112px;
$margin-out-mobile: 27%;
$arrow-size: 48px;

.b-hero {
	// padding-top: 112px;
	margin-bottom: calc($margin-out-mobile + $arrow-size);

	@include bp(medium) {
		margin-bottom: 112px;
	}

	&__container {
		background-image: var(--hero-picture);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		// min-height: 70vh;
		min-height: 400px;

		@include bp(medium) {
			min-height: calc(100vh - $hero-bottom-offset);
			// background-size: 100cover;
			background-position: 15vw center;
		}

		@media screen and (min-width: 1800px) {
			background-size: 77vw auto;
			background-position: right top;
		}
	}

	&__content {
		position: relative;
		z-index: 2;

		@include bp-down(medium) {
			// background-color: $color-brand-1;
			padding: 24px 24px 48px 24px;
			// border-radius: $global-radius-big;
			position: relative;
			top: $margin-out-mobile;
		}

		@include bp(medium) {
			padding: 100px 48px 0 0;
			width: 33%;
		}

		&::before {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			background-image: var(--hero-background);
			background-size: contain;
			background-position: center 15px;
			background-repeat: no-repeat;
			border-radius: $global-radius-big;
			z-index: -1;
			mix-blend-mode: multiply;
			width: 100%;
			height: 100%;

			@include bp(medium) {
				display: block;
				border-radius: 0 0 $global-radius-big 0;
				background-size: 200%;
				background-position: 1px bottom;
				bottom: -$hero-bottom-offset;
				left: auto;
				height: calc(100% + $hero-bottom-offset);
			}
		}

		&::after {
			content: '';
			position: absolute;
			top: 0;
			background-color: $color-brand-1;
			z-index: -2;
			border-radius: $global-radius-big;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;

			@include bp(medium) {
				display: block;
				border-radius: 0 0 $global-radius-big 0;
				left: -33vw;
				width: calc(100% + 33vw);
				height: calc(100% + $hero-bottom-offset);
			}
		}

		h1 {
			font-weight: 700;
			font-size: em(30);
			line-height: 1;
			margin: 0 0 30px 0;
			padding: 0;
			color: $color-white;

			@include bp(medium) {
				margin: 0 0 48px 0;
				font-size: em(64);
				width: 39vw;
				min-width: 500px;
			}
		}

		p {
			font-weight: 500;
			font-size: em(14);
			line-height: 1.43;
			color: $color-white;
			margin: 0;

			@include bp(medium) {
				font-size: em(18);
				line-height: 1.44;
			}
		}
	}

	&__arrow {
		width: $arrow-size;
		height: $arrow-size;
		border-radius: 10px;
		background-color: $color-brand-2;
		cursor: pointer;
		position: absolute;
		bottom: calc(($margin-out-mobile + ($arrow-size / 2)) * -1);
		z-index: 3;

		@include bp(medium) {
			bottom: -24px;
			right: 7vw;
		}

		path {
			stroke: $color-white;
		}
	}

	&__icon {
		margin-right: 11px;
	}
}
