@mixin modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: auto;
	z-index: 100;
	max-width: calc(100% - $wrapper-gutter);
	max-height: calc(100% - (2 * $wrapper-gutter));
	margin: $wrapper-gutter auto;
	display: none;

	&.active {
		display: block;
	}
}

@mixin before {
	content: '';
	display: block;
	position: absolute;
	top: (-$wrapper-gutter);
	bottom: (-$wrapper-gutter);
	right: (-$wrapper-gutter);
	left: (-$wrapper-gutter);
	width: calc(100vw + $wrapper-gutter * 2);
	height: calc(100vh + $wrapper-gutter * 2);
	z-index: -1;
	background-color: rgba($color-black, 0.5);
}

@mixin content {
	overflow: auto;
	max-height: calc(100vh - ($wrapper-gutter * 2));
	border: 1px solid $color-brand-5;
	border-radius: $global-radius-big;
}

@mixin modal-style($type: false) {
	@if $type {
		@include bp-down($type) {
			@include modal;

			&::before {
				@include before;
			}

			&-content {
				@include content;
			}
		}
	} @else {
		@include modal;

		&::before {
			@include before;
		}

		&-content {
			@include content;
		}
	}
}

.c-modal {
	&--mobile {
		@include modal-style(large);
	}

	&--all {
		@include modal-style();
	}

	&__close {
		cursor: pointer;
	}
}
