/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
/* stylelint-disable no-descending-specificity */
.c-main-nav {
	$self: &;

	--main-color-text: #{$color-brand-1};

	@include bp(large) {
		--main-color-text: #{$color-white};
	}

	@include bp-down(large) {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		height: 100vh;
		background-color: $color-white;
		padding-top: 80px;
		overflow: auto;
	}

	&.active {
		display: block;
	}

	&--colored {
		--main-color-text: #{$color-brand-1};

		@include bp(large) {
			--main-color-text: #{$color-brand-1};
		}
	}

	&__list {
		margin: 0;
		padding: 0;

		@include bp(large) {
			gap: 12px;
		}
	}

	&__link {
		font-size: em(16);
		font-weight: 600;
		text-align: center;
		color: var(--main-color-text);
		text-decoration: none;
		padding: em(38) em(8);
		line-height: 1.5;
		border-radius: 0;
		border: 2px solid transparent;
		transition: $global-transition;
		border-bottom-color: $color-brand-8;
		white-space: nowrap;

		path {
			transition: $global-transition;
			stroke: var(--main-color-text);

			@include bp(large) {
				stroke: var(--main-color-text);
			}
		}

		&:hover,
		&:focus {
			color: $color-brand-2;

			path {
				stroke: $color-brand-2;
			}
		}

		@include bp(large) {
			color: var(--main-color-text);
			border-bottom-color: transparent;
			padding: em(12) em(8);
		}

		&--fill {
			path {
				fill: var(--main-color-text);
				stroke: none;

				@include bp(large) {
					fill: var(--main-color-text);
					stroke: none;
				}
			}

			&:hover,
			&:focus {
				path {
					fill: $color-brand-2;
					stroke: none;
				}
			}
		}

		&--border {
			a {
				border-color: white;
				padding: em(12) em(16);
			}
		}

		&.active {
			svg {
				transform: rotate(180deg);
			}
		}
	}

	&__item {
		list-style: none;
		margin: 0;
		padding: 0;
		width: 100%;
		position: relative;

		&:last-child {
			margin-right: 0;

			> a {
				border-bottom-color: transparent;
			}
		}
	}

	&__dropdown {
		margin: 0;
		padding: 0 0 0 35px;
		list-style: none;
		border-radius: $global-radius;
		position: absolute;
		right: 0;
		left: 0;
		text-align: right;
		display: none;
		flex-direction: column;
		align-items: center;

		@include bp(large) {
			padding: 0;
			left: auto;
			align-items: flex-end;
		}

		@include bp-down(large) {
			// padding: 0;
		}

		&.active {
			display: flex;
		}
	}

	&__subitem {
		margin: 0 0 15px 0;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__sublink {
		font-size: em(16);
		font-weight: 600;
		text-align: center;
		color: var(--main-color-text);
		text-decoration: none;
		padding: em(38) em(8);
		line-height: 1.5;
		border-radius: 0;
		border: 2px solid transparent;
		transition: $global-transition;
		white-space: nowrap;

		path {
			transition: $global-transition;
			stroke: var(--main-color-text);

			@include bp(large) {
				stroke: var(--main-color-text);
			}
		}

		&:hover,
		&:focus {
			color: $color-brand-2;

			path {
				stroke: $color-brand-2;
			}
		}

		@include bp(large) {
			color: var(--main-color-text);
			border-bottom-color: transparent;
			padding: em(12) em(8);
		}
	}
}
/* stylelint-enable no-descending-specificity */
