.b-gallery {
	padding: 17px 0 0 0;

	@include bp(medium) {
		padding: 33px 0 100px 0;
	}

	img {
		border-radius: $global-radius-big;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	a {
		height: auto;
	}

	.swiper {
		@include bp-down(medium) {
			margin-right: -15px;
		}
	}
}
