.c-range {
	&__name {
		font-weight: 700;
		font-size: em(20);
		line-height: 1;
		text-align: center;
		color: $color-brand-1;
		margin: 0 0 em(18) 0;

		@include bp(medium) {
			font-size: em(24);
		}
	}

	&__range {
		padding: 24px 0;
		position: relative;
		width: calc(100% - 16px);

		@include bp(large) {
			width: calc(80% - 16px);
		}
	}

	&__input {
		--size-gap: 30px;

		gap: var(--size-gap);

		label {
			--number-columns: 2;

			@include columns;
		}

		input {
			font-family: $font-sans-serif;
			width: 100%;
			border: 2px solid $color-brand-5;
			padding: 12px;
			background-color: transparent;
			border-radius: $global-radius-big;
			font-weight: 500;
			font-size: em(16);
			line-height: 1.5;
			text-align: center;
			color: $color-brand-3;
		}

		&--prefix-suffix {
			label {
				&::after {
					content: attr(data-input-suffix);
					right: 12px;
				}
			}
		}

		&--prefix-suffix,
		&--prefix {
			label {
				position: relative;

				&::before {
					content: attr(data-input-prefix);
					left: 12px;
				}

				&::before,
				&::after {
					position: absolute;
					top: 0;
					bottom: 0;
					display: flex;
					align-items: center;
					background-color: transparent;
					font-style: normal;
					font-weight: 500;
					font-size: em(16);
					line-height: 1.5;
					color: $color-brand-9;
				}
			}

			input {
				padding: 12px 34px;
			}
		}
	}

	&__name,
	&__input {
		width: 100%;

		@include bp(large) {
			width: 80%;
		}
	}
}
