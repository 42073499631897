.b-text-bg {
	&__content {
		z-index: 1;
		font-weight: 500;
		font-size: em(14);
		line-height: 1.43;
		color: $color-brand-3;
		padding: 25px 0 50px 0;

		@include bp(medium) {
			padding: 95px calc(16.66% + 30px) 160px calc(16.66% + 30px);
			font-size: em(16);
			line-height: 1.5;
		}

		&::before {
			background-image: var(--textbg-backgrund);
			background-repeat: no-repeat;
			background-position: center top;
			background-size: contain;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			opacity: 0.5;
			display: block;
			content: '';
			z-index: -1;

			@include bp(medium) {
				display: none;
			}
		}

		p {
			&:last-child {
				margin: 0;
			}
		}
	}

	&__graphic {
		position: absolute;
		top: -50px;
		right: 0;
		bottom: 0;
		left: auto;
		opacity: 0.5;
		display: none;
		z-index: -1;

		@include bp(medium) {
			display: block;
			max-height: 100%;
		}
	}
}
