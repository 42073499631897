.b-mockup {
	--padding-top: 80px;

	padding-top: var(--padding-top);

	@include bp(medium) {
		--padding-top: 110px;

		padding-top: var(--padding-top);
	}

	&__app {
		width: 100%;
		height: calc(100vh - var(--padding-top));
	}
}
